import { autoinject, observable } from 'aurelia-framework';
import {
    BlockListItem,
    BlockType,
    BusinessMap,
    BusinessMapsApiClient,
    GetUtmBlockRequest,
    UtmApiClient
} from 'services/cyber-api';
import { StateApi } from 'services/state-api';
import { ListView, ListViewValidation } from 'components/list-view/list-view';
import { Utilities } from 'utilities/utilities';
import { Toast } from 'utilities/toast';
import { Router } from 'aurelia-router';
import Swal from 'sweetalert2';

@autoinject()
export class UtmConfiguration {
    private loading = false;
    @observable private businessMapSearch: string = undefined;
    private allBusinessMaps: BusinessMap[] = [];
    private businessMaps: BusinessMap[] = [];
    private businessMapsLoading = false;
    private selectedBusinessMap: BusinessMap;
    private selectedBusinessMapHasAdvancedThreatDetection: boolean;

    private ipSearch: string | undefined = undefined;
    private companyIpResults: BlockListItem[] = [];
    private companyIpSkip: number = 0;
    private companyIpTake: number = 7;
    private companyIpTotalCount: number = undefined;
    private companyIpLoading = false;
    private companyIpCollapsed = true;

    private ipListView: ListView;
    private ipResults: BlockListItem[] = [];
    private ipSkip: number = 0;
    private ipTake: number = 30;
    private ipTotalCount: number = undefined;
    private ipLoading = false;
    private ipValidation: ListViewValidation[] | undefined = undefined;

    private domainSearch: string | undefined = undefined;
    private domainListView: ListView;
    private domainResults: BlockListItem[] | undefined = undefined;
    private domainSkip: number = 0;
    private domainTake: number = 25;
    private domainTotalCount: number = undefined;
    private domainLoading = false;
    private domainValidation: ListViewValidation[] | undefined = undefined;

    private companyDomainResults: BlockListItem[] = [];
    private companyDomainSkip: number = 0;
    private companyDomainTake: number = 7;
    private companyDomainTotalCount: number = undefined;
    private companyDomainLoading = false;
    private companyDomainCollapsed = true;

    private iocDomains: BlockListItem[] = [];
    private filteredIocDomains: BlockListItem[] = [];
    private iocDomainLoading = false;
    private iocDomainCollapsed = true;

    private iocIps: BlockListItem[] = [];
    private filteredIocIps: BlockListItem[] = [];
    private iocIpLoading = false;
    private iocIpCollapsed = true;

    private params: any;

    constructor(
        private router: Router,
        private state: StateApi,
        private utmApi: UtmApiClient,
        private businessMapsApi: BusinessMapsApiClient,
    ) {
    }

    private async activate(params: any): Promise<any> {
        this.params = params;

        await this.fetchBusinessMaps();
        this.selectedBusinessMap = this.params?.siteId ? this.businessMaps.find(s => s.ttSiteId == this.params.siteId) : undefined;
    }

    private async attached() {
        await this.resetData();
        await this.fetchCompanyIps(0, true, false);
        await this.fetchCompanyDomains(0, true, false);
        if (this.allBusinessMaps.filter(x => Utilities.hasAdvancedThreatDetection(x)).length > 0) {
            await this.fetchIocIps();
            await this.fetchIocDomains();
        }
    }

    private async setBusinessMap(businessMap: BusinessMap | undefined): Promise<void> {
        // settings the selected business map to null will show only fleet wide blocks.
        this.selectedBusinessMap = businessMap;
        this.selectedBusinessMapHasAdvancedThreatDetection = this.selectedBusinessMap ? Utilities.hasAdvancedThreatDetection(businessMap) : false;
        await this.resetData();
    }

    private async fetchBusinessMaps(): Promise<void> {
        this.businessMapsLoading = true;
        this.allBusinessMaps = await this.businessMapsApi.getAll(this.state.company());
        // sort by utmEnabled and then by name
        this.allBusinessMaps = this.allBusinessMaps.sort((a, b) => a.isUtmActive === b.isUtmActive ? a.name.localeCompare(b.name) : a.isUtmActive ? -1 : 1);
        this.businessMaps = this.allBusinessMaps;
        this.businessMapsLoading = false;
    }

    private async businessMapSearchChanged(): Promise<void> {
        this.selectedBusinessMap = undefined;
        this.businessMaps = this.allBusinessMaps.filter(x => x.name.toLowerCase().includes(this.businessMapSearch.toLowerCase()));
        this.selectedBusinessMap = this.businessMaps.length > 0 ? this.businessMaps[0] : undefined;
        // trigger reload of content
        await this.resetData();
    }

    private async resetData(): Promise<void> {
        this.resetIps();
        this.resetDomains();
    }

    private async resetDomains(): Promise<void> {
        this.domainResults = undefined;
        this.domainSkip = 0;
        this.domainTotalCount = undefined;

        await this.fetchDomains(0, true, false);
    }

    private async resetIps(): Promise<void> {
        this.ipResults = [];
        this.ipSkip = 0;
        this.ipTotalCount = undefined;

        await this.fetchIps(0, true, false);
    }

    private async resetCompanyIps(): Promise<void> {
        this.companyIpResults = [];
        this.companyIpSkip = 0;
        this.companyIpTotalCount = undefined;

        await this.fetchCompanyIps(0, true, false);
    }

    private async resetCompanyDomains(): Promise<void> {
        this.companyDomainResults = [];
        this.companyDomainSkip = 0;
        this.companyDomainTotalCount = undefined;

        await this.fetchCompanyDomains(0, true, false);
    }

    private async addIp(ip: string): Promise<boolean> {
        try {
            let validIp = await this.validateIp(ip);
            if (validIp) {
                this.ipListView.resetValidation();
                if (!this.selectedBusinessMap) {
                    await this.utmApi.addBlock(this.state.company(), ip, BlockType.Ip);
                } else {
                    await this.utmApi.addBlockBySite(this.selectedBusinessMap.ttSiteId.toString(), this.state.company(), ip, BlockType.Ip);
                }
                // Wait a second for the block to be added.
                await new Promise(resolve => setTimeout(resolve, 1000));
                await this.resetIps();
                if (!this.selectedBusinessMap)
                    await this.resetCompanyIps();
                return true;
            } else {
                return false;
            }
        } catch (error) {
            Toast.error('Could not add IP');
        }
    }

    private async addDomain(domain: string): Promise<boolean> {
        try {
            let validDomain = await this.validateDomain(domain);
            if (validDomain) {
                this.domainListView.resetValidation();
                if (!this.selectedBusinessMap) {
                    await this.utmApi.addBlock(this.state.company(), domain, BlockType.Domain);
                } else {
                    await this.utmApi.addBlockBySite(this.selectedBusinessMap.ttSiteId.toString(), this.state.company(), domain, BlockType.Domain);
                }
                // Wait a second for the block to be added.
                await new Promise(resolve => setTimeout(resolve, 1000));
                await this.resetDomains();
                if (!this.selectedBusinessMap)
                    await this.resetCompanyDomains();

                return true;
            } else {
                return false;
            }
        } catch (error) {
            Toast.error('Could not add Domain');
        }
    }

    private async searchIps(query: string): Promise<void> {
        this.ipSearch = query;
        await this.resetIps();
        await this.resetCompanyIps();
        this.filteredIocIps = this.iocIps.filter(x => x.value.startsWith(query));
    }

    private async searchDomains(query: string): Promise<void> {
        this.domainSearch = query;
        await this.resetDomains();
        await this.resetCompanyDomains();
        this.filteredIocDomains = this.iocDomains.filter(x => x.value.startsWith(query));
    }

    private async validateIp(inputValue: string): Promise<boolean> {
        if (inputValue === '' || inputValue === null || inputValue === undefined) {
            this.ipValidation = [{
                message: 'Please enter a valid ip.',
                type: 'error'
            }];
            return false;
        } else {
            try {
                await this.utmApi.validateBlock(this.state.company(), inputValue, BlockType.Ip);
                // if the selected business map has advanced threat detection, validate the domain against the ioc domains
                if (this.selectedBusinessMapHasAdvancedThreatDetection) {
                    if (this.iocIps.find(x => x.value === inputValue)) {
                        this.ipValidation = [{
                            message: 'IP is already in the IOC list.',
                            type: 'error'
                        }];
                        return false;
                    } else {
                        this.ipValidation = undefined;
                    }
                } else {
                    this.ipValidation = undefined;
                }

                return true;
            } catch (errors) {
                console.log(errors)
                if (errors && errors.length > 0) {
                    this.ipValidation = errors.map((error) => {
                        return {
                            message: error,
                            type: 'error'
                        };
                    });
                } else {
                    this.ipValidation = [{
                        message: `Something went wrong. Please try again.`,
                        type: 'error'
                    }];
                }
                return false;
            }
        }
    }

    private async validateDomain(inputValue: string): Promise<boolean> {
        if (inputValue === '' || inputValue === null || inputValue === undefined) {
            this.domainValidation = [{
                message: 'Please enter a valid domain.',
                type: 'error'
            }];
            return false;
        } else {
            try {
                await this.utmApi.validateBlock(this.state.company(), inputValue, BlockType.Domain);
                // if the selected business map has advanced threat detection, validate the domain against the ioc domains
                if (this.selectedBusinessMapHasAdvancedThreatDetection) {
                    if (this.iocDomains.find(x => x.value === inputValue)) {
                        this.domainValidation = [{
                            message: 'Domain is already in the IOC list.',
                            type: 'error'
                        }];
                        return false;
                    } else {
                        this.domainValidation = undefined;
                    }
                } else {
                    this.domainValidation = undefined;
                }
                return true;
            } catch (errors) {
                if (errors && errors.length > 0) {
                    this.domainValidation = errors.map((error) => {
                        return {
                            message: error,
                            type: 'error'
                        };
                    });
                } else {
                    this.domainValidation = [{
                        message: `Something went wrong. Please try again.`,
                        type: 'error'
                    }];
                }
                return false;
            }
        }
    }

    private async fetchIocDomains(): Promise<void> {
        this.iocDomainLoading = true;
        this.iocDomains = await this.utmApi.getIocBlocks(this.state.company(), BlockType.Domain);
        this.filteredIocDomains = this.iocDomains;
        this.iocDomainLoading = false;
    }

    private async fetchIocIps(): Promise<void> {
        this.iocIpLoading = true;
        this.iocIps = await this.utmApi.getIocBlocks(this.state.company(), BlockType.Ip);
        this.filteredIocIps = this.iocIps;
        this.iocIpLoading = false;
    }

    private async fetchCompanyIps(topIndex: number, isAtBottom: boolean, isAtTop: boolean): Promise<void> {
        if (!isAtBottom) return;

        // When at the end of the list and no more data is available, short-circuit as there's nothing left to fetch
        if (this.companyIpTotalCount === this.companyIpResults.length && this.companyIpTotalCount !== undefined) return;
        this.companyIpLoading = true;

        let getUtmBlockRequest = new GetUtmBlockRequest({
            companyId: this.state.company(),
            take: this.companyIpTake,
            skip: this.companyIpSkip,
            search: this.ipSearch,
            type: BlockType.Ip,
            filter: undefined,
            siteId: undefined
        });
        let pagedResult = await this.utmApi.getBlocks(
            this.state.company(),
            getUtmBlockRequest
        )

        if (!pagedResult) {
            this.companyIpLoading = false;
            return;
        }

        this.companyIpResults = this.companyIpResults.concat(pagedResult.items);
        this.companyIpTotalCount = pagedResult.total;
        this.companyIpSkip += this.companyIpTake;
        this.companyIpLoading = false;
    }

    private async fetchCompanyDomains(topIndex: number, isAtBottom: boolean, isAtTop: boolean): Promise<void> {
        if (!isAtBottom) return;

        // When at the end of the list and no more data is available, short-circuit as there's nothing left to fetch
        if (this.companyDomainTotalCount === this.companyDomainResults.length && this.companyDomainTotalCount !== undefined) return;
        this.companyDomainLoading = true;

        let getUtmBlockRequest = new GetUtmBlockRequest({
            companyId: this.state.company(),
            take: this.companyDomainTake,
            skip: this.companyDomainSkip,
            search: this.domainSearch,
            type: BlockType.Domain,
            filter: undefined,
            siteId: undefined
        });
        let pagedResult = await this.utmApi.getBlocks(
            this.state.company(),
            getUtmBlockRequest
        )

        if (!pagedResult) {
            this.companyDomainLoading = false;
            return;
        }

        this.companyDomainResults = this.companyDomainResults.concat(pagedResult.items);
        this.companyDomainTotalCount = pagedResult.total;
        this.companyDomainSkip += this.companyDomainTake;
        this.companyDomainLoading = false;
    }

    private async fetchIps(topIndex: number, isAtBottom: boolean, isAtTop: boolean): Promise<void> {
        if (!isAtBottom) return;

        // When at the end of the list and no more data is available, short-circuit as there's nothing left to fetch
        if (this.ipTotalCount === this.ipResults.length && this.ipTotalCount !== undefined) return;
        this.ipLoading = true;

        let getUtmBlockRequest = new GetUtmBlockRequest({
            companyId: this.state.company(),
            take: this.ipTake,
            skip: this.ipSkip,
            search: this.ipSearch,
            type: BlockType.Ip,
            filter: undefined,
            siteId: this.selectedBusinessMap?.ttSiteId.toString()
        });
        let pagedResult = await this.utmApi.getBlocks(
            this.state.company(),
            getUtmBlockRequest
        )

        if (!pagedResult) {
            return;
        }

        this.ipResults = this.ipResults.concat(pagedResult.items);
        this.ipTotalCount = pagedResult.total;
        this.ipSkip += this.ipTake;
        this.ipLoading = false;
    }

    private async fetchDomains(topIndex: number, isAtBottom: boolean, isAtTop: boolean): Promise<void> {
        if (!isAtBottom) return;

        // When at the end of the list and no more data is available, short-circuit as there's nothing left to fetch
        if (this.domainTotalCount === this.domainResults?.length && this.domainTotalCount !== undefined) return;
        this.domainLoading = true;

        let getUtmBlockRequest = new GetUtmBlockRequest({
            companyId: this.state.company(),
            take: this.domainTake,
            skip: this.domainSkip,
            search: this.domainSearch,
            type: BlockType.Domain,
            filter: undefined,
            siteId: this.selectedBusinessMap?.ttSiteId.toString(),
        });
        let pagedResult = await this.utmApi.getBlocks(
            this.state.company(),
            getUtmBlockRequest
        )

        if (!pagedResult) {
            return;
        }

        this.domainResults = this.domainResults?.concat(pagedResult.items) ?? pagedResult.items;
        this.domainTotalCount = pagedResult.total;
        this.domainSkip += this.domainTake;
        this.domainLoading = false;
    }

    private async removeIp(id: string): Promise<void> {
        const response = await Swal.fire({
            title: 'Delete IP',
            html: 'Are you sure you want to delete this IP?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, delete IP',
        });

        if (response.value)
            try {
                this.ipLoading = true;
                await this.utmApi.deleteBlockById(id, this.state.company());
                await this.resetIps();
                await this.resetCompanyIps();
                Toast.success('IP has successfully been deleted');
            } catch (error) {
                this.ipLoading = false
                Toast.error('Could not delete IP');
            }
    }

    private async removeDomain(id: string): Promise<void> {
        const response = await Swal.fire({
            title: 'Delete Domain',
            html: 'Are you sure you want to delete this Domain?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, delete Domain',
        });

        if (response.value)
            try {
                this.domainLoading = true;
                await this.utmApi.deleteBlockById(id, this.state.company());
                await this.resetDomains();
                await this.resetCompanyDomains();
                Toast.success('Domain has successfully been deleted');
            } catch (error) {
                this.domainLoading = false
                Toast.error('Could not delete Domain');
            }
    }

    /**
     * Generates a link to this specific threat and copies it to the clipboard
     */
    private copyLink(businessMapId: string): void {
        try {
            // Generate a direct link to this vessel
            const directLink = this.router.generate('UTM Configuration', {
                siteId: businessMapId
            }, { absolute: true });
            // Copy link to clipboard
            Utilities.copyToClipboard(directLink);
        } catch (error) {
            Toast.warning('Unable to copy link to Vessel to clipboard');
            return;
        }

        Toast.info('Link to Vessel copied to clipboard');
    }

    private toggleCompanyIps() {
        this.companyIpCollapsed = !this.companyIpCollapsed;
    }

    private toggleCompanyDomains() {
        this.companyDomainCollapsed = !this.companyDomainCollapsed;
    }

    private toggleIocIps() {
        this.iocIpCollapsed = !this.iocIpCollapsed;
    }

    private toggleIocDomains() {
        this.iocDomainCollapsed = !this.iocDomainCollapsed;
    }
}
